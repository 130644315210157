import React, { useState } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import axios from 'axios';

const RegisterForm: React.FC<{ onSwitchToLogin: () => void }> = ({ onSwitchToLogin }) => {
  const [registerForm, setRegisterForm] = useState({
    userType: 1,
    username: '',
    password: '',
    passwordAgain: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    patronymic: '',
    birthdate: '',
    companyName: '',
    idOfUser: ''
  });
  
  const [loading, setLoading] = useState(false); // Loading state for the registration process

  const handleRegisterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
  };

  const handleRegister = async () => {
    const data = {
      userType: Number(registerForm.userType),
      username: registerForm.username,
      password: registerForm.password,
      passwordAgain: registerForm.passwordAgain,
      email: registerForm.email,
      phone: registerForm.phone || null,
      firstName: registerForm.firstName,
      lastName: registerForm.lastName,
      patronymic: registerForm.patronymic,
      birthdate: registerForm.birthdate,
      companyName: registerForm.userType === 2 ? registerForm.companyName : null,
      idOfUser: registerForm.idOfUser
    };

    setLoading(true); // Start loading animation
    try {
      const response = await axios.post('https://roite.dev/api/geoportal/auth/register-user', data);
      if (response.data.isSuccess) {
        message.success('Registration successful!');
        onSwitchToLogin(); // Switch to login page after successful registration
      } else {
        message.error(response.data.message || 'Registration failed');
      }
    } catch (error: any) {
      if (error.response) {
        message.error(error.response.data.message || 'An error occurred during registration.');
      } else {
        message.error('An error occurred during registration.');
      }
    } finally {
      setLoading(false); // Stop loading animation after completion
    }
  };

  return (
    <Spin spinning={loading} tip="Registering..."> {/* Spin component wraps the entire form */}
      <Form layout="vertical" onFinish={handleRegister}>
        <Form.Item
          label="User Type"
          name="userType"
          rules={[{ required: true, message: "'User Type' must not be empty." }]}
        >
          <Input name="userType" value={registerForm.userType} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item
          label="Username"
          name="username"
          rules={[
            { required: true, message: "Username is required." },
            { min: 6, message: "Username must be between 6 and 50 characters." },
            { max: 50, message: "Username must be between 6 and 50 characters." }
          ]}
        >
          <Input name="username" value={registerForm.username} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Password is required." },
            { min: 8, message: "Password must be at least 8 characters long." }
          ]}
        >
          <Input.Password name="password" value={registerForm.password} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="passwordAgain"
          dependencies={['password']}
          rules={[
            { required: true, message: "Please confirm your password." },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match.'));
              },
            }),
          ]}
        >
          <Input.Password name="passwordAgain" value={registerForm.passwordAgain} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Email is required." },
            { type: 'email', message: 'Email is not a valid email address.' }
          ]}
        >
          <Input name="email" value={registerForm.email} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            { required: true, message: "Firstname is required." },
            { min: 3, message: "Firstname must be between 3 and 20 characters." },
            { max: 20, message: "Firstname must be between 3 and 20 characters." }
          ]}
        >
          <Input name="firstName" value={registerForm.firstName} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            { required: true, message: "Lastname is required." },
            { min: 3, message: "Lastname must be between 3 and 20 characters." },
            { max: 20, message: "Lastname must be between 3 and 20 characters." }
          ]}
        >
          <Input name="lastName" value={registerForm.lastName} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item
          label="Patronymic"
          name="patronymic"
          rules={[
            { required: true, message: "Patronymic is required." },
            { min: 3, message: "Patronymic must be between 3 and 20 characters." },
            { max: 20, message: "Patronymic must be between 3 and 20 characters." }
          ]}
        >
          <Input name="patronymic" value={registerForm.patronymic} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item
          label="Birthdate"
          name="birthdate"
          rules={[
            { required: true, message: "Birthdate is required." },
            { pattern: /^\d{4}-\d{2}-\d{2}$/, message: 'Birthdate must be a valid date in YYYY-MM-DD format.' }
          ]}
        >
          <Input name="birthdate" value={registerForm.birthdate} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item
          label="Company Name"
          name="companyName"
        >
          <Input name="companyName" value={registerForm.companyName} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item
          label="User ID"
          name="idOfUser"
          rules={[{ required: true, message: "Id of User is required." }]}
        >
          <Input name="idOfUser" value={registerForm.idOfUser} onChange={handleRegisterInputChange} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Register
          </Button>
          <Button type="link" onClick={onSwitchToLogin}>
            Already have an account? Login
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default RegisterForm;

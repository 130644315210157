import axios from "axios";
import { WmsLayerInfo } from "../config/type";

export const getWmsLayerInfos = async (url: string, groupName: string, visibleLayerNames: string[]): Promise<WmsLayerInfo[]> => {
  try {
    const response = await axios.get(url, {
      params: {
        service: "WMS",
        version: "1.3.0",
        request: "GetCapabilities",
      },
      headers: {
        Accept: "application/xml",
      },
      responseType: "text",
    });
    const layers: WmsLayerInfo[] = [];

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(response.data, "text/xml");
    const layerElements = xmlDoc.getElementsByTagName("Layer");

    let groupTitle = groupName;
    if (layerElements.length > 0) {
      const groupTitle0 = layerElements[0].getElementsByTagName("Title")[0]?.textContent;
      const groupTitle1 = layerElements[0].getElementsByTagName("Title")[1]?.textContent;
      if (groupTitle1) groupTitle = groupTitle1;
      else if (groupTitle0) groupTitle = groupTitle0;
    }

    for (let i = 0; i < layerElements.length; i++) {
      const layerElement = layerElements[i];
      const nameElements = Array.from(layerElement.children).filter((child) => child.tagName === "Name");
      const titleElements = Array.from(layerElement.children).filter((child) => child.tagName === "Title");

      if (nameElements.length > 0 && titleElements.length > 0) {
        const layerName = nameElements[0].textContent;
        const layerTitle = titleElements[0].textContent;

        if (layerName && layerTitle) {
          layers.push({
            groupTitle,
            layerName: layerName.startsWith(`${groupName}:`) ? layerName : `${groupName}:${layerName}`,
            layerTitle,
            layerVisible: visibleLayerNames.includes(layerName.startsWith(`${groupName}:`) ? layerName : `${groupName}:${layerName}`),
          });
        }
      }
    }

    return layers;
  } catch (error) {
    console.error("WMS GetCapabilities request failed", error);
    return [];
  }
};

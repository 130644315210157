import React, { useEffect } from "react";
import { Feature } from 'ol';
import Polygon from 'ol/geom/Polygon';
import { Modify, Translate } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { GeoJSON } from 'ol/format';
import { Collection } from 'ol';
import { useMap } from "../app/MapContext";
import { fromLonLat } from "ol/proj";
interface PolygonEditorProps {
  onPolygonSave: (geoJSON: any) => void;
}

const PolygonEditor: React.FC<PolygonEditorProps> = ({ onPolygonSave }) => {
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;

    const view = map.getView();
    view.setZoom(18);

    const source = new VectorSource();
    const vectorLayer = new VectorLayer({
      source: source,
      style: {
        'fill-color': 'rgba(255, 255, 255, 0.2)',
        'stroke-color': '#ffcc33',
        'stroke-width': 2,
        'circle-radius': 7,
        'circle-fill-color': '#ffcc33',
      },
    });

    map.addLayer(vectorLayer);

    const center = view.getCenter() || fromLonLat([0, 0]);
    const size = 100;
    const halfSize = size / 2;
    const polygonCoords = [[
      [center[0] - halfSize, center[1] - halfSize],
      [center[0] + halfSize, center[1] - halfSize],
      [center[0] + halfSize, center[1] + halfSize],
      [center[0] - halfSize, center[1] + halfSize],
      [center[0] - halfSize, center[1] - halfSize],
    ]];

    const polygon = new Polygon(polygonCoords);
    const polygonFeature = new Feature({ geometry: polygon });
    source.addFeature(polygonFeature);

    const modify = new Modify({ source: source });
    map.addInteraction(modify);

    const featuresArray = source.getFeatures();
    const featuresCollection = new Collection(featuresArray);
    const translate = new Translate({ features: featuresCollection });
    map.addInteraction(translate);

    const geoJSONFormat = new GeoJSON();
    const polygonGeoJSON = geoJSONFormat.writeFeatureObject(polygonFeature);
    onPolygonSave(polygonGeoJSON); // Veriyi parent bileşene gönder
    const updatePolygonGeoJSON = () => {
      if (polygonFeature) {
        const updatedGeoJSON = geoJSONFormat.writeFeatureObject(polygonFeature);
        onPolygonSave(updatedGeoJSON);
      }
    };

    // Add event listeners for modification and translation
    modify.on('modifyend', updatePolygonGeoJSON);
    translate.on('translateend', updatePolygonGeoJSON);
    updatePolygonGeoJSON();
    return () => {
      map.removeLayer(vectorLayer);
      map.removeInteraction(modify);
      map.removeInteraction(translate);
      // Remove event listeners to prevent memory leaks
      modify.un('modifyend', updatePolygonGeoJSON);
      translate.un('translateend', updatePolygonGeoJSON);
    };
  }, [map, onPolygonSave]);

  return null;
};

export default PolygonEditor;

import React, { useState } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import axios from 'axios';
// import { useAuth } from '../app/AuthContext';

const EmailVerificationForm: React.FC<{ onVerificationSuccess: () => void }> = ({ onVerificationSuccess }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
//   const {  accessToken } = useAuth();
  const handleVerificationInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(e.target.value);
  };

  const handleVerifyEmail = async () => {
    if (!verificationCode.trim()) {
      message.error('Please enter the verification code.');
      return;
    }

    setLoading(true); // Start loading spinner
    try {
      const response = await axios.post(`https://roite.dev/api/geoportal/auth/confirm-email-after-register/${verificationCode}`, {
        code: verificationCode,
      },
        // { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      if (response.data.isSuccess) {
        message.success('Email verification successful!');
        onVerificationSuccess(); // Proceed after successful verification
      } else {
        message.error(response.data.message || 'Email verification failed.');
      }
    } catch (error: any) {
      if (error.response) {
        message.error(error.response.data.message || 'An error occurred during email verification.');
      } else {
        message.error('An error occurred during email verification.');
      }
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <Spin spinning={loading} tip="Verifying...">
      <Form layout="vertical" onFinish={handleVerifyEmail}>
        <Form.Item
          label="Verification Code"
          name="verificationCode"
          rules={[{ required: true, message: "Please enter the verification code sent to your email." }]}
        >
          <Input
            name="verificationCode"
            value={verificationCode}
            onChange={handleVerificationInputChange}
            placeholder="Enter your verification code"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Verify Email
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default EmailVerificationForm;

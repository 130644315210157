import React, { useState } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import { useAuth } from '../app/AuthContext';
import axios from 'axios';

type LoginFormProps = {
  onSwitchToRegister: () => void;
  onSwitchToForgot: () => void;
  onSwitchToVerify: () => void;
  onLoginSuccess: () => void;
};

const LoginForm: React.FC<LoginFormProps> = ({ onSwitchToRegister, onSwitchToForgot,onSwitchToVerify, onLoginSuccess }) => {
  const { login } = useAuth();
  const [loginForm, setLoginForm] = useState({ username: '', password: '' });
  const [loading, setLoading] = useState(false); // Yükleniyor durumunu izlemek için state

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginForm({
      ...loginForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const { username, password } = loginForm;
    setLoading(true); // Yükleniyor durumunu başlat
    try {
      const response = await axios.post('https://roite.dev/api/geoportal/auth/login', { username, password });
      if (response.data.isSuccess) {
        login(response.data.data.username, response.data.data.accessToken, response.data.data.refreshToken);
        message.success('Login successful!');
        onLoginSuccess(); 
      } else {
        message.error(response.data.message || 'Login failed');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        message.error(error.response.data.message || 'Login failed. Please try again.');
      } else {
        message.error('An unknown error occurred.');
      }
    } finally {
      setLoading(false); // İşlem tamamlandığında yükleniyor durumunu sonlandır
    }
  };

  return (
    <Spin spinning={loading} tip="Logging in..."> {/* Spin bileşeni ile yükleniyor animasyonu */}
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="Username">
          <Input name="username" value={loginForm.username} onChange={handleInputChange} />
        </Form.Item>
        <Form.Item label="Password">
          <Input.Password name="password" value={loginForm.password} onChange={handleInputChange} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Login
          </Button>
          <Button type="link" onClick={onSwitchToRegister}>
            Don't have an account? Register now
          </Button>
          {/* <Button type="link" onClick={onSwitchToForgot}>Forgot password?</Button> */}
          <Button type="link" onClick={onSwitchToVerify}>Verification email</Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default LoginForm;

import React, { useEffect } from "react";
import Geocoder from "ol-geocoder";
import "ol-geocoder/dist/ol-geocoder.min.css";
import { useMap } from "../app/MapContext";
import { Control } from "ol/control"; // Import Control directly
import axios from 'axios';

const GeocoderComponent: React.FC = () => {
  
// Helper function to encode username and password to Base64
const encodeBasicAuth = (username: string, password: string): string => {
  const credentials = `${username}:${password}`;
  return `Basic ${btoa(credentials)}`;
};

// Example custom provider using axios with Basic Auth
const CustomProvider = {
  async getParameters(options: any) {
    // Base64 encoded username and password for Basic Auth
    const authHeader = encodeBasicAuth('roite1', 'K6BPnrxrSTqc8Mcyl0MS');

    // Construct the URL with the search query
    const url = `https://map.cadastru.md/geoserver/w_rsuat/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=apl:mv_punct_constr&maxFeatures=200&outputFormat=application/json&cql_filter=(${options.query
      .split(" ")
      .map((term: string) => `search_column LIKE '%${term}%'`)
      .join(" AND ")})`;
    console.log("🚀 ~ getParameters ~ url:", url)

    // Axios request with Basic Auth
    const response = await axios.get(url, {
      headers: {
        Authorization: authHeader,
      },
    });
    console.log("🚀 ~ getParameters ~ response:", response)

    return response.data; // Return GeoJSON data
  },

  async handleResponse(data: any) {
    if (data.features && data.features.length) {
      return data.features.map((feature: any) => ({
        lon: feature.geometry.coordinates[0],
        lat: feature.geometry.coordinates[1],
        address: {
          name: feature.properties.gfullname,
        },
        // bbox: feature.bbox,
      }));
    }
    return [];
  },
};

  
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;

    const geocoder = new Geocoder("nominatim", {
      provider: CustomProvider as unknown as (query: string) => Promise<any>,
      lang: "en",
      placeholder: "Search for a location",
      limit: 5,
      autoComplete: true,
      keepOpen: false,
    });

    // Add Geocoder to Map
    map.addControl(geocoder as unknown as Control);

    // Center map on selected address
    geocoder.on("addresschosen", (evt: any) => {
      if (evt.bbox) {
        map.getView().fit(evt.bbox, { duration: 500 });
      } else {
        map.getView().animate({ center: evt.coordinate, zoom: 14 });
      }
    });

    // Cleanup on unmount
    return () => {
      map.removeControl(geocoder as unknown as Control);
    };
  }, [map]);

  return null;
};

export default GeocoderComponent;

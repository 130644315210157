import React, { useState } from 'react';
import { Button, Layout, Modal, message, Spin } from 'antd';
import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';
import EmailVerificationForm from '../components/EmailVerificationForm'; // EmailVerificationForm bileşenini içe aktar
import axios from 'axios';
import { useAuth } from '../app/AuthContext';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

const { Header: LayoutHeader } = Layout;

type Props = {
  logoUrl: string;
};

export const Header: React.FC<Props> = ({ logoUrl }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState<'login' | 'register' | 'verify' | 'forgot'>('login'); // Modal modlarını yönetmek için
  const [loading, setLoading] = useState(false); // Loading state for logout
  const { user, accessToken, logout } = useAuth();

  const handleOpenModal = () => setIsModalVisible(true);
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalMode('login');
  };

  const handleLogout = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        'https://roite.dev/api/geoportal/auth/logout',
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      if (response.data.isSuccess) {
        message.success('Logout successful!');
        logout();
      } else {
        message.error('Logout failed.');
      }
    } catch (error) {
      message.error('An error occurred during logout.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

 

  const handleVerificationSuccess = () => {
    message.success('Email verified successfully!');
    setModalMode('login'); // Doğrulama sonrası login ekranına geçiş yap
  };

  return (
    <>
      <LayoutHeader
        style={{
          backgroundColor: "white",
          borderBottom: "1px solid #01adef66",
          padding: 10,
          paddingRight: 20,
          height: 65,
          boxShadow: "0 1px 4px rgba(0,21,41,.98)",
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img src={logoUrl} alt="logo" style={{ height: 40, padding: "8px 0 0px 8px" }} />
          {user ? (
            <Spin spinning={loading} tip="Logging out...">
              <Button size="small" type="text" style={{ fontWeight: 600 }} onClick={handleLogout} disabled={loading}>
                Logout
              </Button>
            </Spin>
          ) : (
            <Button size="small" type="text" style={{ fontWeight: 600 }} onClick={handleOpenModal}>
              Login
            </Button>
          )}
        </div>
      </LayoutHeader>

      <Modal
        title={
          modalMode === 'login' ? "Login" :
          modalMode === 'register' ? "Register" : 
          "Email Verification"
        }
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        {modalMode === 'login' && (
          <LoginForm
            onSwitchToRegister={() => setModalMode('register')}
            onSwitchToForgot={() => setModalMode('forgot')}
            onSwitchToVerify={() => setModalMode('verify')}
            onLoginSuccess={() => setIsModalVisible(false)}
          />
        )}
        {modalMode === 'register' && (
          <RegisterForm
            onSwitchToLogin={() => setModalMode('login')}
         
          />
        )}
        {modalMode === 'verify' && (
          <EmailVerificationForm onVerificationSuccess={handleVerificationSuccess} />
        )}
        {modalMode === 'forgot' && (
          <ForgotPasswordForm  />)}
      </Modal>
    </>
  );
};

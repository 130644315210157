// AuthProvider.tsx
import axios, { AxiosError } from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';

interface AuthContextType {
  user: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  login: (username: string, accessToken: string, refreshToken: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  // Kullanıcı girişini ayarlayan login fonksiyonu
  const login = (username: string, accessToken: string, refreshToken: string) => {
    setUser(username);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  };

  // Çıkış yapan logout fonksiyonu
  const logout = () => {
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
  };

  // Yeni bir access token almak için refresh token ile istek yapar
  // const refreshAccessToken = async () => {
  //   try {
  //     const response = await axios.post('https://roite.dev/api/geoportal/auth/refresh-token', {
  //       refreshToken: refreshToken,
  //     },{headers: {Authorization: `Bearer ${accessToken}`}});

  //     const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;

  //     setAccessToken(newAccessToken);
  //     setRefreshToken(newRefreshToken);

  //     return newAccessToken; // Yeni token'ı döndürür
  //   } catch (error) {
  //     console.error('Token yenileme başarısız:', error);
  //     logout();
  //   }
  // };

  // useEffect(() => {
  //   const interceptor = axios.interceptors.response.use(
  //     response => response,
  //     async (error: AxiosError) => {
  //       if (error.response?.status === 401 && refreshToken) {
  //         try {
  //           const newAccessToken = await refreshAccessToken();
  //           if (newAccessToken && error.config) { // error.config null değilse devam et
  
  //             // error.config.headers kontrolü ve güncellenmesi
  //             if (error.config.headers) {
  //               // headers'in tipini AxiosHeaders olarak kabul etmesini sağlıyoruz
  //               (error.config.headers as any).set('Authorization', `Bearer ${newAccessToken}`);
  //             }
              
  //             return axios(error.config); // Aynı isteği yeni token ile yeniden gönderir
  //           }
  //         } catch (refreshError) {
  //           console.error('Yeniden giriş yapılamadı:', refreshError);
  //           logout();
  //         }
  //       }
  //       return Promise.reject(error);
  //     }
  //   );
  
  //   return () => axios.interceptors.response.eject(interceptor);
  // }, [refreshToken]);
  
  

  return (
    <AuthContext.Provider value={{ user, accessToken, refreshToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

import axios from "axios";
import { FeatureCollection } from "geojson";
import bbox from "@turf/bbox";
import { Extent } from "ol/extent";

// GeoJSON formatında gelen veriden extenti hesaplıyoruz
export const getLayerExtent = async (wmsUrl: string, layerName: string): Promise<Extent | null> => {
  try {
    // WMS üzerinden GeoJSON almak için GetFeature sorgusu yapıyoruz
    const response = await axios.get(wmsUrl, {
      params: {
        service: "WFS", // WMS yerine WFS kullanarak GeoJSON alıyoruz
        version: "1.0.0",
        request: "GetFeature",
        typeName: layerName, // Katman adı
        srsName: "EPSG:3857", // EPSG:4326 koordinat sistemini kullanıyoruz
        outputFormat: "application/json", // GeoJSON formatında veri almak için
      },
    });
    
    const geojson: FeatureCollection = response.data;

    // Turf.js ile extenti hesaplıyoruz
    const extent = bbox(geojson);

    // [minX, minY, maxX, maxY] formatında extenti döndürüyoruz
    return extent as Extent;
  } catch (error) {
    console.error("Error fetching layer extent:", error);
    return null;
  }
};

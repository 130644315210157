import React from "react";
import { useMap } from "../app/MapContext";
import { Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { CustomControlButton } from "./base/CustomControlButton";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ScaleLine } from "ol/control.js";

export const PrintControl: React.FC = () => {
  const { map } = useMap();

  const onPrint = () => {
    const mapCenter = map?.getView().getCenter();
    const scaleLine = map
      ?.getControls()
      .getArray()
      .find((control) => control instanceof ScaleLine) as ScaleLine;

    if (map && mapCenter) {
      const exportOptions: any = {
        useCORS: true,
        ignoreElements: function (element: HTMLElement) {
          const className = element.className?.toString() || "";
          // Exclude elements with 'ol-control' or 'ucontrol-print' classes to hide map controls and buttons
          return className.includes("ol-control") || className.includes("ucontrol-print");
        },
        
      };

      document.body.style.cursor = "progress";

      const format = "a4";
      const resolution = 96;
      const dim = [297, 210];
      const width = Math.round((dim[0] * resolution) / 25.4);
      const height = Math.round((dim[1] * resolution) / 25.4);
      const viewResolution = map.getView().getResolution();

      // Temporarily remove the scale bar if needed
      if (scaleLine) {
        map.removeControl(scaleLine);
      }

      map.once("rendercomplete", function () {
        exportOptions.width = width;
        exportOptions.height = height;

        html2canvas(map.getViewport(), exportOptions).then(function (canvas) {
          const pdf = new jsPDF("landscape", undefined, format);
          pdf.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 0, 0, dim[0], dim[1]);
          pdf.save("map.pdf");

          // Re-add the scale bar and reset map size
          if (scaleLine) {
            map.addControl(scaleLine);
          }
          scaleLine.setDpi(96);
          map.getTargetElement().style.width = "100%";
          map.getTargetElement().style.height = "100%";
          map.updateSize();
          map.getView().setResolution(viewResolution);

          document.body.style.cursor = "auto";
        });
      });

      // Set print size for high resolution
      scaleLine.setDpi(resolution);
      map.getTargetElement().style.width = width + "px";
      map.getTargetElement().style.height = height + "px";
      map.updateSize();
    }
  };

  return (
    <CustomControlButton classname="ucontrol-print" key="print">
      <Button icon={<PrinterOutlined style={{ height: 32, verticalAlign: "middle" }} />} onClick={() => onPrint()} />
    </CustomControlButton>
  );
};

import React, { useMemo } from "react";
import { useMap } from "../app/MapContext";
import { flyTo } from "../utils/flyTo";
import type { FormProps } from "antd";
import { Button, Form, Input } from "antd";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import CircleStyle from "ol/style/Circle";

type FieldType = {
  lon?: string;
  lat?: string;
  zoom?: number;
};

type Props = {};

export const ZoomToCoordinate: React.FC<Props> = () => {
  const { map } = useMap();
  const source = useMemo(() => new VectorSource(), []);

  const vector = useMemo(() => {
    const instance = new VectorLayer({
      source,
      style: new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({
            color: "#a60510",
          }),
          stroke: new Stroke({
            color: "#ffffff",
            width: 2,
          }),
        }),
      }),
    });

    (instance as any).id = "marker";
    return instance;
  }, [source]);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    if (map) {
      const lon = parseFloat(values.lon || "0");
      const lat = parseFloat(values.lat || "0");
      const zoom = Number(values.zoom || "12");
      flyTo(lon, lat, zoom);

      // Eski katmanı kontrol et, yoksa ekle
      const existingLayer = map
        .getLayers()
        .getArray()
        .find((layer: any) => layer.id === "marker");

      if (!existingLayer) {
        map.addLayer(vector);
      }

      // Yeni koordinatlarla marker ekle
      const feature = new Feature({
        geometry: new Point(fromLonLat([lon, lat])),
      });

      source.clear(); // Önceki markerları temizle
      source.addFeature(feature); // Yeni marker ekle
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      style={{ paddingTop: 24 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ lon: "28.8322", lat: "47.0245", zoom: 17 }}
    >
      <Form.Item<FieldType> label="X" name="lon" rules={[{ required: true, message: "Longitude is required" }]}>
        <Input />
      </Form.Item>

      <Form.Item<FieldType> label="Y" name="lat" rules={[{ required: true, message: "Latitude is required" }]}>
        <Input />
      </Form.Item>

      <Form.Item<FieldType> label="Zoom" name="zoom" rules={[{ required: true, message: "Zoom is required" }]}>
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Go to Coordinate
        </Button>
      </Form.Item>
    </Form>
  );
};

import { Form, Input, DatePicker, Select, Button, Upload, message, Radio, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../app/AuthContext';

const { Option } = Select;

interface SendRequestFormProps {
  polygonData: any; // Polygon GeoJSON data
  onSubmit: (values: any) => void;
  isLoading: boolean; // Loading state for polygon data
}

const SendRequestForm: React.FC<SendRequestFormProps> = ({ polygonData, onSubmit, isLoading }) => {
  const {  accessToken } = useAuth();
  const [form] = Form.useForm();
  const [applicantType, setApplicantType] = useState<'individual' | 'legal'>('individual');
  
  // State for dropdown options
  const [activities, setActivities] = useState<any[]>([]);
  const [sectors, setSectors] = useState<any[]>([]);

  // Error handling for API calls
  const [error, setError] = useState<string>('');

  // Fetch activities and sectors from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch activities
        const activitiesResponse = await axios.get('https://roite.dev/api/geoportal/select-lists/activities',
     
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        setActivities(activitiesResponse.data.data);

        console.log(activitiesResponse)

        // Fetch sectors
        const sectorsResponse = await axios.get('https://roite.dev/api/geoportal/select-lists/company-sectors',
     
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        setSectors(sectorsResponse.data.data);
      } catch (err) {
        setError('Failed to load options.');
        message.error('Error fetching data');
      }
    };

    fetchData();
  }, []);

  const onFinish = (values: any) => {
    if (!polygonData || Object.keys(polygonData).length === 0) {
      message.error("Polygon data is missing. Please draw a polygon before submitting.");
      return;
    }

    onSubmit({ ...values, polygon: polygonData }); // Include polygon data
    message.success('Form Submitted!');
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error('Form submission failed');
  };

  const handleUpload = (file: any) => {
    message.success(`${file.name} file uploaded successfully`);
    return false; // Prevent actual upload
  };

  return (
    <div style={{ padding: '20px' }}>
      {isLoading ? (
        <Spin size="large" />
      ) : error ? (
        <div style={{ color: 'red' }}>{error}</div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {/* Project Information */}
          <Form.Item
            label="Project Information"
            name="projectInfo"
            rules={[{ required: true, message: 'Please provide the project details' }]}
          >
            <Input.TextArea placeholder="Provide a detailed description of the project" />
          </Form.Item>

       {/* Work Activities Dropdown */}
       <Form.Item
            label="Type of Activities"
            name="activities"
            rules={[{ required: true, message: 'Please select an activity' }]}
          >
            <Select placeholder="Select activity type">
              {activities.map((activity) => (
                <Option key={activity.activityId} value={activity.activityId}>
                  {activity.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Dates */}
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[{ required: true, message: 'Please select the start date' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="endDate"
            dependencies={['startDate']}
            rules={[
              { required: true, message: 'Please select the end date' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value.isAfter(getFieldValue('startDate'))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('End date must be after start date'));
                },
              }),
            ]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>

          {/* Applicant Type */}
          <Form.Item label="Applicant Type" name="applicantType" initialValue={"individual"}>
            <Radio.Group
              onChange={(e) => setApplicantType(e.target.value)}
              value={applicantType}
            >
              <Radio value="individual">Individual</Radio>
              <Radio value="legal">Legal Entity</Radio>
            </Radio.Group>
          </Form.Item>

          {/* Conditional Fields */}
          {applicantType === 'legal' && (
            <>
              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[{ required: true, message: 'Please enter the company name' }]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>

              <Form.Item
                label="Sector"
                name="sector"
                rules={[{ required: true, message: 'Please select a sector' }]}
              >
                <Select placeholder="Select sector">
                  {sectors.map((sector) => (
                    <Option key={sector.companySectorId} value={sector.companySectorId}>
                      {sector.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          {/* Address */}
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: 'Please enter your address' }]}
          >
            <Input placeholder="Address" />
          </Form.Item>

          {/* Mobile Number */}
          <Form.Item
            label="Mobile Number"
            name="mobileNumber"
            rules={[{ required: true, message: 'Please enter your mobile number' }]}
          >
            <Input placeholder="Mobile Number" />
          </Form.Item>

          {/* Email */}
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email' }]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          {/* Repeat Email */}
          <Form.Item
            label="Repeat Email"
            name="repeatEmail"
            dependencies={['email']}
            rules={[
              { required: true, message: 'Please confirm your email' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two emails do not match!'));
                },
              }),
            ]}
          >
            <Input placeholder="Repeat Email" />
          </Form.Item>

          {/* Upload */}
          <Form.Item label="Attachments" name="attachments">
            <Upload beforeUpload={handleUpload}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          {/* Submit */}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default SendRequestForm;

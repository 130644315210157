import React from "react";
import { MapProvider } from "./MapContext";
import { BaseLayerControl } from "../controls/BaseLayerControl";
import { PrintControl } from "../controls/PrintControl";
import { AuthProvider } from "./AuthContext";
import GeocoderComponent from "../controls/GeocodeControl";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <MapProvider>
        <BaseLayerControl />
        <PrintControl />
        <GeocoderComponent />
      </MapProvider>
    </AuthProvider>
  );
};

export default App;
